<template>
  <div class="fb-member" v-if="dialogVisible" :style="`${clientWidth}; left:${left}px;${clientHeight}`">
    <div class="freeze-member" v-if="freeze" :style="`left:${btnLeft * 100 + 50}%`">
      <div>
        <span
          >您发起的团购已有<span style="color: red; font-size: 18px">{{ number }}</span
          >人付款。</span
        >
      </div>
      <div>满{{ totalNum }}人后才能开通。赶快分享给其他人。</div>
      <div class="freeze-member-btn">
        <div class="share" @click="share">分享</div>
        <div class="more" @click="more">了解更多</div>
      </div>
    </div>
    <div v-else class="no-member">
      <div style="color: #fff; font-size: 14px; margin-bottom: 10px">当前使用的手机号为{{ hidenPhone(this.userInfo.phone) }},并不是会员。</div>
      <div class="no-member-btn" @click="toMember">成为会员</div>
      <div style="color: #fff; font-size: 14px; margin-top: 10px; display: flex; justify-content: space-between">
        <span @click="changePhone">已是会员？</span><span @click="applyTry">联系客服</span>
      </div>
      <van-popup v-model="inputPhoneShow" position="top">
        <div>如果您已是会员，请填写开通会员的手机号。</div>
        <van-field v-model="inputPhone" clearable placeholder="请输入手机号" v-show="inputPhoneShow" @input="setPhone">
          <template #button>
            <van-button size="small" type="info" v-show="inputPhoneBtn" @click="send">确定</van-button>
          </template>
        </van-field>
      </van-popup>
      <van-popup v-model="applyTryShow" position="top">
        <van-field v-model="nickname" clearable placeholder="填写姓名"> </van-field>
        <van-field v-model="companyName" clearable placeholder="填写单位名称"> </van-field>
        <div style="color: #0099ff; text-align: center; margin-top: 20px">
          <span @click="sendApply">发送申请</span>
        </div>
      </van-popup>
      <div class="btn-box">
        <div class="share-btn" @click="share">分享返50元！</div>
        <div class="more-btn" @click="more">了解更多</div>
      </div>
    </div>

    <div class="unionId">{{ `${this.getUnionId}` }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'fb-member',
  props: {
    left: {
      type: Number,
      default: () => 0,
    },
    top: {
      type: Number,
      default: () => 0,
    },
    btnLeft: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      clientWidth: 'width:' + document.body.clientWidth + 'px',
      dialogVisible: false,
      freeze: false,
      number: 1,
      totalNum: 3,
      clientHeight: 'min-height:' + document.body.clientHeight + 'px',
      kind: '',
      secKind: '',
      inputPhone: '',
      inputPhoneShow: false,
      applyTryShow: false,
      inputPhoneBtn: false,
      nickname: '',
      companyName: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'organization', 'token']),
    getUnionId() {
      if (this.userInfo.unionid) {
        const a = this.userInfo.unionid
        return a.substring(a.length - 4, a.length)
      }
      return null
    },
    getAccount() {
      if (this.userInfo.account) {
        const a = this.userInfo.account
        return a.substring(a.length - 3, a.length)
      }
      return null
    },
  },
  methods: {
    ...mapActions('user', ['getOrganizationData']),
    hidenPhone(phone) {
      const result = '*******' + phone.substr(7)
      return result
    },
    applyTry() {
      if (this.organization && this.organization.name) {
        this.companyName = this.organization.name
      }
      this.nickname = this.userInfo.nickName
      this.applyTryShow = !this.applyTryShow
    },
    changePhone() {
      this.inputPhoneShow = !this.inputPhoneShow
    },
    async sendApply() {
      const params = {
        name: this.nickname,
        phone: this.userInfo.phone,
        companyName: this.companyName,
        province: this.userInfo.province,
        city: this.userInfo.city,
        // applyUser: new Date(),
        applyUserId: this.userInfo.id,
        applyCreateTime: new Date().getTime(),
        resourceLists: [
          {
            resourceType: this.kind === 'dashuju' ? 'ANALYSIS' : 'LEGAL_SEARCH',
          },
        ],
      }
      this.$axios.post(`${this.$base}/admin/resource/apply/add`, params).then((res) => {
        if (res.data.code === 200) {
          this.$notify({
            type: 'success',
            message: '已通知客服，稍后客服联系您，请留意。',
          })
          this.applyTryShow = false
        } else {
          this.$notify(res.data.message)
        }
      })
    },
    setPhone() {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (reg.test(this.inputPhone)) {
        this.inputPhoneBtn = true
      } else {
        this.inputPhoneBtn = false
      }
    },
    send() {
      this.$axios({
        method: 'GET',
        data: {},
        url: `${this.$base}/management/users/bind/phone?phone=${this.inputPhone}`,
        headers: {
          'Authentication-Token': this.token,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$notify({
              type: 'success',
              message: '更换成功',
            })
          } else {
            this.$notify(res.data.message)
          }
        })
        .catch(() => {
          this.$notify('更换失败')
        })
    },
    toMember() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toVipRechargeView()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      }
    },

    // 获取目前几个人团购
    getMemberNum() {
      this.$axios.get(`${this.$base}/management/user/${this.user.id}/order/group`).then((res) => {
        if (res.data.code === 200) {
          this.number = res.data.data.paidNum
          this.totalNum = res.data.data.totalNum
        }
      })
    },

    async share() {
      const ua = navigator.userAgent
      // if (ua !== this.$ua.a && ua !== this.$ua.a) {
      //   this.$notify('非原生端不支持 分享')
      //   return
      // }
      const secKind = this.secKind
      console.log('请求参数', this.kind, secKind)
      const res = await this.$axios.get(`${this.$base}/management/video?kind=${this.kind}&seckind=${secKind}`)
      console.log('video接口返回', res)
      const { code, data } = res.data
      let params
      if (code === 200 && data.content.length > 0) {
        const temp = {
          videoUrl: data.content[0].videoUrl,
          vphotoUrl: data.content[0].vphotoUrl,
          vtitle: data.content[0].vtitle,
        }
        params = JSON.stringify(temp)
      }
      console.log('传给AND的参数', params)
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.toShareApp(params)
      } else if (ua === 'fb-flutter-Webview') {
        window.toShareApp.postMessage(params)
      } else {
        this.$notify('异常，暂无法使用')
      }
    },

    more() {
      const ua = navigator.userAgent
      // if (ua !== this.$ua.a && ua !== this.$ua.a) {
      //   this.$notify('非原生端不支持')
      //   return
      // }
      if (ua === '') {
        // eslint-disable-next-line no-undef
        AND2JS.toHelpCenterView()
      } else if (ua === 'fb-iOS-Webview') {
        this.$notify('ios端不支持该功能')
      } else if (ua === 'fb-flutter-Webview') {
        this.$notify('本版本不支持该功能')
      } else {
        this.$notify('异常，暂无法使用')
      }
    },

    // 暴露给原生端的方法，获取用户状态
    memberSuccessCallBack() {
      this.$axios.get(`${this.$base}/management/users/${this.userInfo.id}`).then((res) => {
        if (res.data.code === 200) {
          this.$notify(`用户状态=>${res.data.data.memberStatus}`)
          if (res.data.data.memberStatus === 1) {
            this.dialogVisible = false
            this.freeze = false
          } else if (res.data.data.memberStatus === -1) {
            this.getMemberNum()
            this.freeze = true
          }
        }
      })
    },
  },
  created() {
    this.getOrganizationData()
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/handleMemberSuccess
    this.$hook(this.$bridge.handleMemberSuccess, this.memberSuccessCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.handleMemberSuccess)
    })
    this.$bus.$on('fb-member', (params) => {
      console.log('show member dialog')
      // 暂时去掉IOS的会员限制
      const ua = navigator.userAgent
      const iOSUA = 'fb-iOS-Webview'
      if (ua === iOSUA) {
        this.dialogVisible = false
        return
      }
      if (params) {
        this.dialogVisible = params.dialogVisible
        this.secKind = params.secKind
        this.kind = params.kind
      }
      if (this.userInfo) {
        if (this.userInfo.memberStatus === -1) {
          this.freeze = true
          this.getMemberNum()
        } else {
          this.freeze = false
        }
      } else {
        this.freeze = false
      }
    })
  },
  destroyed() {
    this.$bus.$off('fb-member')
    console.log('destroyed member dialog')
  },
  watch: {
    btnLeft(val) {
      // console.log('val', val)
      if (this.kind === 'dashuju') {
        if (val === 0) {
          this.secKind = 'panli'
        } else if (val === 1) {
          this.secKind = 'falv'
        } else {
          this.secKind = 'zhishi'
        }
      }
    },
  },
}
</script>
<style lang="stylus" scoped>
.fb-member
  width 100%
  height 100%
  position absolute
  left -15px
  top 0px
  background rgba(0,0,0,0.2)
  z-index 998
  & .freeze-member
    position fixed
    background rgba(255, 153, 0, 1)
    width 80%
    height 150px
    color #FFF
    display flex
    flex-direction column
    align-items center
    justify-content center
    font-size 14px
    left 50%
    margin-left -40%
    border-radius 5px
    top 250px
    & .freeze-member-btn
      display flex
      flex-direction row
      align-items center
      margin-top 20px
      & .share
        width 80px
        height 30px
        border-radius 5px
        text-align center
        line-height 30px
        border 1px solid #ffffff
        font-size 14px
      & .more
        width 80px
        height 30px
        border-radius 5px
        text-align center
        line-height 30px
        border 1px solid #ffffff
        margin-left 20px
        font-size 14px
        color #FFF
  & .no-member
    position fixed
    top 250px
    left 0%
    z-index 999
    width 100%
    padding 0 20px
    box-sizing border-box
    & .no-member-btn
      background rgba(255, 153, 0, 1)
      color #ffffff
      border-radius 5px
      width 200px
      height 35px
      text-align center
      line-height 35px
    & .btn-box
      width 300px
      display flex
      flex-direction row
      align-items center
      justify-content space-between
      margin-top 100px
      // position fixed
      // bottom 50px
      // left 50%
      // margin-left -150px
      // z-index 999
      & > div
        width 100px
        height 35px
        line-height 35px
        text-align center
        color #FFF
        border-radius 5px
      & .share-btn
        background rgba(255, 153, 0, 1)
      & .more-btn
        background #999999
    & .van-popup
      padding 10px 20px
      box-sizing border-box
  & .invite-dialog
    position fixed
    top 250px
    left 0
    z-index 999
    width 100%
    padding 0
    box-sizing border-box
  & .unionId
    position fixed
    bottom 20px
    right 50px
    z-index 999
    color #FFF
</style>
