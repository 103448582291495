var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dialogVisible
    ? _c(
        "div",
        {
          staticClass: "fb-member",
          style: `${_vm.clientWidth}; left:${_vm.left}px;${_vm.clientHeight}`,
        },
        [
          _vm.freeze
            ? _c(
                "div",
                {
                  staticClass: "freeze-member",
                  style: `left:${_vm.btnLeft * 100 + 50}%`,
                },
                [
                  _c("div", [
                    _c("span", [
                      _vm._v("您发起的团购已有"),
                      _c(
                        "span",
                        { staticStyle: { color: "red", "font-size": "18px" } },
                        [_vm._v(_vm._s(_vm.number))]
                      ),
                      _vm._v("人付款。"),
                    ]),
                  ]),
                  _c("div", [
                    _vm._v(
                      "满" +
                        _vm._s(_vm.totalNum) +
                        "人后才能开通。赶快分享给其他人。"
                    ),
                  ]),
                  _c("div", { staticClass: "freeze-member-btn" }, [
                    _c(
                      "div",
                      { staticClass: "share", on: { click: _vm.share } },
                      [_vm._v("分享")]
                    ),
                    _c(
                      "div",
                      { staticClass: "more", on: { click: _vm.more } },
                      [_vm._v("了解更多")]
                    ),
                  ]),
                ]
              )
            : _c(
                "div",
                { staticClass: "no-member" },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#fff",
                        "font-size": "14px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _vm._v(
                        "当前使用的手机号为" +
                          _vm._s(_vm.hidenPhone(this.userInfo.phone)) +
                          ",并不是会员。"
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "no-member-btn",
                      on: { click: _vm.toMember },
                    },
                    [_vm._v("成为会员")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "#fff",
                        "font-size": "14px",
                        "margin-top": "10px",
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c("span", { on: { click: _vm.changePhone } }, [
                        _vm._v("已是会员？"),
                      ]),
                      _c("span", { on: { click: _vm.applyTry } }, [
                        _vm._v("联系客服"),
                      ]),
                    ]
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { position: "top" },
                      model: {
                        value: _vm.inputPhoneShow,
                        callback: function ($$v) {
                          _vm.inputPhoneShow = $$v
                        },
                        expression: "inputPhoneShow",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v("如果您已是会员，请填写开通会员的手机号。"),
                      ]),
                      _c("van-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.inputPhoneShow,
                            expression: "inputPhoneShow",
                          },
                        ],
                        attrs: { clearable: "", placeholder: "请输入手机号" },
                        on: { input: _vm.setPhone },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button",
                              fn: function () {
                                return [
                                  _c(
                                    "van-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.inputPhoneBtn,
                                          expression: "inputPhoneBtn",
                                        },
                                      ],
                                      attrs: { size: "small", type: "info" },
                                      on: { click: _vm.send },
                                    },
                                    [_vm._v("确定")]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1101062076
                        ),
                        model: {
                          value: _vm.inputPhone,
                          callback: function ($$v) {
                            _vm.inputPhone = $$v
                          },
                          expression: "inputPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { position: "top" },
                      model: {
                        value: _vm.applyTryShow,
                        callback: function ($$v) {
                          _vm.applyTryShow = $$v
                        },
                        expression: "applyTryShow",
                      },
                    },
                    [
                      _c("van-field", {
                        attrs: { clearable: "", placeholder: "填写姓名" },
                        model: {
                          value: _vm.nickname,
                          callback: function ($$v) {
                            _vm.nickname = $$v
                          },
                          expression: "nickname",
                        },
                      }),
                      _c("van-field", {
                        attrs: { clearable: "", placeholder: "填写单位名称" },
                        model: {
                          value: _vm.companyName,
                          callback: function ($$v) {
                            _vm.companyName = $$v
                          },
                          expression: "companyName",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#0099ff",
                            "text-align": "center",
                            "margin-top": "20px",
                          },
                        },
                        [
                          _c("span", { on: { click: _vm.sendApply } }, [
                            _vm._v("发送申请"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "btn-box" }, [
                    _c(
                      "div",
                      { staticClass: "share-btn", on: { click: _vm.share } },
                      [_vm._v("分享返50元！")]
                    ),
                    _c(
                      "div",
                      { staticClass: "more-btn", on: { click: _vm.more } },
                      [_vm._v("了解更多")]
                    ),
                  ]),
                ],
                1
              ),
          _c("div", { staticClass: "unionId" }, [
            _vm._v(_vm._s(`${this.getUnionId}`)),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }