<template>
  <div class="checkBox">
    <div class="content-checkBox"
         @click="change"
         :style="{'background-color':flag||indet?'#409eff':'','border-color':flag||indet?'#409eff':''}">
      <div class="heng"
           v-if="indet"></div>
      <div class="gou"
           v-else-if="flag"></div>
    </div>
    <div class="conten"
         ref="slot"
         v-if="remove">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['indeterminate', 'flag'],
  model: {
    prop: 'flag',
    event: 'changeflag'
  },
  data() {
    return {
      indet: false,
      remove: true
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.slot && this.$refs.slot.innerHTML.length === 0) {
        this.remove = false
      }
    })
  },
  methods: {
    change() {
      if (this.indet) {
        this.$emit('changeflag', true)
        this.indet = false
      } else {
        this.$emit('changeflag', !this.flag)
      }
      this.$emit('change', !this.flag)
    }
  },
  watch: {
    indeterminate: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$emit('flag', false)
        }
        this.indet = val
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.checkBox
  display inline-block
  overflow hidden
.content-checkBox
  width 15px
  height 15px
  border 1px solid #999999
  border-radius 2px
  position relative
  transition all 0.5s
  vertical-align top
  display inline-block
  .gou
    width 8px
    height 5px
    border-left 1.5px solid white
    border-bottom 1.5px solid white
    position absolute
    transform rotate(-45deg)
    top calc(50% - 4px)
    left calc(50% - 4px)
  .heng
    width 8px
    height 2px
    background-color white
    position absolute
    top calc(50% - 1px)
    left calc(50% - 4px)
.conten
  display inline-block
  overflow hidden
  margin-left 10px
  width calc(100% - 0.4rem - 0.2666667rem)
</style>
