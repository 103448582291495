var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkBox" }, [
    _c(
      "div",
      {
        staticClass: "content-checkBox",
        style: {
          "background-color": _vm.flag || _vm.indet ? "#409eff" : "",
          "border-color": _vm.flag || _vm.indet ? "#409eff" : "",
        },
        on: { click: _vm.change },
      },
      [
        _vm.indet
          ? _c("div", { staticClass: "heng" })
          : _vm.flag
          ? _c("div", { staticClass: "gou" })
          : _vm._e(),
      ]
    ),
    _vm.remove
      ? _c(
          "div",
          { ref: "slot", staticClass: "conten" },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }