export const debounce = (fn, delay) => {
  // 维护一个 timer，用来记录当前执行函数状态
  let timer = null
  return function() {
    // 通过 ‘this’ 和 ‘arguments’ 获取函数的作用域和变量
    const context = this
    const args = arguments
    // 清理掉正在执行的函数，并重新执行
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}

// 生成唯一id
export const genID = (length = 6) => {
  return Number(
    Math.random()
      .toString()
      .substr(3, length) + Date.now()
  ).toString(36)
}
// 判断类型
export const typeOf = obj => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}
